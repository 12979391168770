<template>
    <div class="estimates">
        <div class="estimates__request-list" :class="[viewSummaryEstimates ? 'close' : 'open']">
            <div class="estimates__header">
              <div class="title-container ai-c">
                <p class="title">Estimates</p>
                <div class="count" v-if="projectQuoteListIsLoaded">
                    <p>{{ getFilteredProjectQuotes.length || 0 }}</p>
                </div>
              </div>
            </div>
            <div class="estimates__all-request">
               <AllEstimateRequestComponent
                v-if="projectQuoteListIsLoaded && !isLoading"
                :projectQuotes="getFilteredProjectQuotes"
                :selectedProjectQuote="selectedProjectQuote"
                @select-project-quote="selectProjectQuote"
              />
            </div>
        </div>
        <div class="estimates__summary"
          v-if="projectQuoteListIsLoaded && getFilteredProjectQuotes.length && !isLoading"
          :class="[viewSummaryEstimates ? 'open' : 'close']">
            <div class="estimates__header" v-if="canCreate">
              <div class="title-container jc-sb ai-c">
                <p class="title flex-row ai-c jc-c"><span class="show-in-mobile"><el-icon :size="15" color="#0C0F4A" @click="toggleSummaryDetailsState"><ArrowLeftBold/></el-icon></span> Summary</p>
                <!-- <p class="profile-cta">View profile</p> -->
              </div>
              <div class="action-container" v-if="canAcceptOrRejectQuote">
                <div class="agreement-action">
                   <el-checkbox v-model="agreeToCondition">
                    <p>
                      By accepting the estimate you will agree with the<br> terms within the <span>Service Agreement</span>
                    </p>
                  </el-checkbox>
                </div>
                <div class="button-action flex-row ai-c">
                   <el-button type="primary" class="button"
                     :class="{'active': agreeToCondition}" :disabled="!agreeToCondition"
                     round @click="onAcceptQuote" :loading="submitting">ACCEPT</el-button>
                   <p class="reject" @click="onRejectQuote">REJECT</p>
                </div>
              </div>
            </div>
            <div class="estimates__summary-content">
                <div class="estimates__summary-column-one" v-if="selectedProjectQuote">
                  <ProfileBoxComponent class="profile" :user="selectedProjectQuote?.projectQuoteCreator"/>
                  <EstimateDetailsCard :user="selectedProjectQuote?.projectQuoteCreator"/>
                </div>
                <div class="estimates__summary-column-two"
                  v-if="selectedProject && selectedProjectQuote && projectQuoteDetailsLoaded"
                >
                    <EstimatePhaseSummaryCard
                      :projectQuote="selectedProjectQuote"
                      :projectRefId="selectedProject?.refId"
                      :quoteId="confirmedQuote?.id"
                    />
                </div>
                <div class="estimates__action-container-mobile flex-row ai-c jc-sb" v-if="canAcceptOrRejectQuote">
                  <div class="agreement-action">
                    <el-checkbox v-model="agreeToCondition">
                      <p>
                        By accepting the estimate you will agree with the<br> terms within the <span>Service Agreement</span>
                      </p>
                    </el-checkbox>
                  </div>
                  <div class="button-action flex-row ai-c">
                    <el-button type="primary" class="button"
                      :class="{'active': agreeToCondition}" round
                      :disabled="!agreeToCondition" :loading="submitting" @click="onAcceptQuote">
                      ACCEPT
                    </el-button>
                    <el-button type="primary"
                      class="button" round :loading="submitting"
                      @click="onRejectQuote">
                      REJECT
                    </el-button>
                  </div>
                </div>
            </div>
        </div>
        <EmptyStateComponent
          v-if="projectQuoteListIsLoaded && !getFilteredProjectQuotes.length && !isLoading"
          description="No estimates receive yet" class="estimates__empty-state"/>
        <Loader v-show="!projectQuoteListIsLoaded && !getFilteredProjectQuotes.length && isLoading"/>
        <!-- MODAL -->
        <AcceptEstimateModal
          :show="acceptEstimateModal"
          @on-confirm="onAcceptService"
          @on-cancel="onCancelModals"/>
        <RejectEstimateModal
          :show="rejectEstimateModal"
          :loading="loadingConfirmRejectQuote"
          @on-confirm="submitRejectProjectQuote" @on-cancel="onCancelModals"/>
        <ServiceAgreementModal
          :show="serviceAgreementModal" v-if="selectedProjectQuote"
          :project="selectedProject" :projectQuote="selectedProjectQuote"
          :loading="loadingConfirmAcceptQuote" @on-confirm="submitAcceptProjectQuote"
          @on-cancel="onCancelModals"/>
    </div>
</template>
<script>
import { ArrowLeftBold } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import Loader from '@/core/components/ui/Loader.vue';
import { ProjectJobStatusEnum, ProjectQuoteStatusEnum } from '@/core/packages/shared-library';
import ProfileBoxComponent from '@/modules/dashboard/components/ProfileBoxComponent.vue';
import AllEstimateRequestComponent from '@/modules/project-details-children/components/AllEstimateRequestComponent.vue';
import EmptyStateComponent from '@/modules/project-details-children/components/EmptyStateComponent.vue';
import EstimateDetailsCard from '@/modules/project-details-children/components/EstimateDetailsCard.vue';
import EstimatePhaseSummaryCard from '@/modules/project-details-children/components/EstimatePhaseSummaryCard.vue';
import AcceptEstimateModal from '@/modules/project-details-children/components/modal/AcceptEstimateModal.vue';
import RejectEstimateModal from '@/modules/project-details-children/components/modal/RejectEstimateModal.vue';
import ServiceAgreementModal from '@/modules/project-details-children/components/modal/ServiceAgreementModal.vue';

import { PROJECT_QUOTES_STORE } from '../../../store/modules/project-quotes';
// eslint-disable-next-line import/no-cycle
import { PROJECTS_STORE } from '../../../store/modules/projects';
import { USERS_STORE } from '../../../store/modules/users';
// import { PROJECT_OVERVIEW_ROUTE } from '../../project-overview/routes';
import { PROJECTS_ROUTE } from '../../projects/routes';

export default defineComponent({
  components: {
    AllEstimateRequestComponent,
    ProfileBoxComponent,
    EstimateDetailsCard,
    EstimatePhaseSummaryCard,
    ArrowLeftBold,
    AcceptEstimateModal,
    RejectEstimateModal,
    ServiceAgreementModal,
    EmptyStateComponent,
    Loader
  },

  data() {
    return {
      cardCount: 10,
      agreeToCondition: false,
      viewSummaryEstimates: false,
      colors: [
        '#4A92E5',
        '#D84F50',
        '#8454DD',
        '#EB8C49',
        '#3E72E1',
        '#5DBA59'
      ],
      colorsWithOpacity: [
        'rgba(74, 146, 229, 0.1)',
        'rgba(216, 79, 80, 0.1)',
        'rgba(132, 84, 221, 0.1)',
        'rgba(235, 140, 73, 0.1)',
        'rgba(62, 114, 225, 0.1)',
        'rgba(93, 186, 89, 0.1)'
      ],

      projectQuoteListIsLoaded: false,
      selectedProject: null,
      projectQuoteList: [],
      selectedProjectQuote: null,
      projectQuoteDetailsLoaded: false,
      openConfirmProceedAcceptProjectQuoteModal: false,
      openConfirmProceedRejectProjectQuoteModal: false,
      loadingConfirmAcceptQuote: false,
      loadingConfirmRejectQuote: false,
      filterProjectQuoteString: '',
      updatedState: false,
      rejectEstimateModal: false,
      acceptEstimateModal: false,
      serviceAgreementModal: false,

      canAcceptOrRejectQuote: true,

      confirmedQuote: null,

      isLoading: false
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    // ...mapGetters(['isLoading']),
    ...mapGetters(USERS_STORE, ['user', 'wsRole', 'isWsAdmin']),
    ...mapGetters(PROJECTS_STORE, [
      'currentSelectedProject',
    ]),

    canCreate() {
      const { wsRole, isWsAdmin } = this;
      let show = true;

      if (wsRole && !isWsAdmin) {
        show = !show;
      }

      return show;
    },

    getFilteredProjectQuotes() {
      let result = this.projectQuoteList;

      if (this.filterProjectQuoteString) {
        result = result.filter((projectQuote) => {
          return projectQuote.notes.toLowerCase().includes(this.filterProjectQuoteString.toLowerCase());
        });
      }

      return result;
    }
  },
  methods: {
    ...mapActions(['setIsLoading']),
    ...mapActions(USERS_STORE, ['initializeUserState']),

    ...mapActions(PROJECTS_STORE, [
      'getProjectByRefId',
      'setSelectedProjectJobStatus',
      'setCurrentSelectedProject',
      'setHasGetProjectFinished'
    ]),

    ...mapActions(PROJECT_QUOTES_STORE, [
      'getProjectQuotesByProject',
      'getProjectQuoteDetails',
      'getCustomerPay',
      'updateProjectQuoteStatus',
      'getTotalStageCost',
      'calculateServiceFee'
    ]),

    gotoSchedule() {
      const params = {
        projectRefId: this.selectedProject.refId,
        quoteId: this.confirmedQuote.id
      };

      this.$router.push({ name: 'estimate-schedule', params });
    },

    toggleSummaryDetailsState() {
      this.viewSummaryEstimates = !this.viewSummaryEstimates;
    },

    onCancelModals() {
      this.rejectEstimateModal = false;
      this.acceptEstimateModal = false;
      this.serviceAgreementModal = false;
    },

    onAcceptService() {
      this.serviceAgreementModal = true;
    },

    onAcceptQuote() {
      this.acceptEstimateModal = true;
    },

    onRejectQuote() {
      this.rejectEstimateModal = true;
    },

    assignPhaseColor(selectedProjectQuote) {
      const { projectStages } = selectedProjectQuote;
      const projectStagesWithColors = [];

      // REMOVE FROM THE LIST THE TYPE: MATERIALS
      for (let i = 0; i < projectStages.length; i++) {
        if (projectStages[i].type === 'materials') {
          projectStagesWithColors.push(...projectStages.splice(i, 1));
        //   console.log(this.finalFilteredSummaryData);
        }
      }
      // REASSIGN ORIGINAL INDEX TO NEW COLOR ORDER INDEX
      projectStages.forEach((i, index) => {
        // RESET LOOP TO 0 AFTER EVERY 6 INSTANCES
        index %= 6; // eslint-disable-line no-param-reassign
        // ADD NEW KEY VALUE PAIR CALLED COLOR
        i.color = this.colors[index]; // eslint-disable-line no-param-reassign
        i.colorWithOpacity = this.colorsWithOpacity[index]; // eslint-disable-line no-param-reassign
      });
      projectStagesWithColors.unshift(...projectStages);
      //   console.log(this.finalFilteredSummaryData);
      this.selectedProjectQuote.projectStages = projectStagesWithColors;
    },

    async initialize() {
      this.isLoading = true;
      // this.setIsLoading(true);
      const { projectRefId } = this.$route.params;

      await this.getProjectByRefId(projectRefId)
        .then((response) => {
          if (response) {
            this.selectedProject = response;
            this.setSelectedProjectJobStatus(this.selectedProject?.projectJobStatus);

            this.setHasGetProjectFinished(true);
            this.setCurrentSelectedProject(response);

            this.canAcceptOrRejectQuote = [ProjectJobStatusEnum.QUOTES_RECEIVED].includes(this.selectedProject?.projectJobStatus?.id);
          }
        })
        .catch((error) => {
          this.$notify.error({
            message: error && error?.message ? error?.message : 'Project not found.'
          });
          this.$router.push({ name: PROJECTS_ROUTE });
        });

      const getProjectQuoteListRequest = await this.getProjectQuotesByProject(this.selectedProject?.id);

      if (getProjectQuoteListRequest?.data?.length) {
        this.projectQuoteList = getProjectQuoteListRequest?.data;
        const [defaultProjectQuote] = this.projectQuoteList;

        await this.selectProjectQuote(defaultProjectQuote.id);

        if (this.projectQuoteList.length) {
          const confirmedQuote = this.projectQuoteList.filter((quote) => quote.projectQuoteStatus.id === ProjectQuoteStatusEnum.QUOTE_CONFIRMED);

          if (confirmedQuote.length) {
            const [quote] = confirmedQuote;
            this.confirmedQuote = quote;

            this.gotoSchedule();
          }
        }

        this.projectQuoteListIsLoaded = true;
        // await this.setIsLoading(false);
        this.isLoading = false;
      } else {
        this.projectQuoteDetailsLoaded = true;
        this.projectQuoteListIsLoaded = true;
        // this.setIsLoading(false);
        this.isLoading = false;
      }
    },

    async selectProjectQuote(projectQuoteId) {
      const projectId = this.selectedProject?.id;
      this.projectQuoteDetailsLoaded = false;
      const getProjectQuoteDetails = await this.getProjectQuoteDetails({ projectQuoteId, projectId });

      if (getProjectQuoteDetails) {
        this.selectedProjectQuote = getProjectQuoteDetails;
      } else {
        this.$notify.error({
          title: 'Project Quote Details Error.',
          message: 'Error fetching project quote details at the moment. Please try to refresh the page.',
        });
      }
      this.assignPhaseColor(this.selectedProjectQuote);

      const { totalWithServiceFee, totalServiceFee } = getProjectQuoteDetails;

      this.selectedProjectQuote.quotePrice = {
        total: totalWithServiceFee,
        serviceFee: totalServiceFee
      };

      this.selectedProjectQuote.quoteTotalDuration = this.selectedProjectQuote.projectStages.map((stage) => {
        return stage.totalDuration;
      }).reduce((a, b) => a + b, 0);

      this.projectQuoteDetailsLoaded = true;
      this.toggleSummaryDetailsState();
    },

    filterProjectQuotes(filterString) {
      this.filterProjectQuoteString = filterString;
    },

    async submitAcceptProjectQuote() {
      this.loadingConfirmAcceptQuote = true;
      const projectQuoteId = this.selectedProjectQuote?.id;
      const formData = {
        projectQuoteStatusId: ProjectQuoteStatusEnum.QUOTE_ACCEPTED,
        agreeTermsConsumerDisclosure: true
      };

      await this.updateProjectQuoteStatus({ projectId: this.selectedProject?.id, projectQuoteId, formData })
        .then(async (response) => {
          if (response) {
            this.$notify.success({
              title: 'Project Quote Accepted.',
              message: 'You successfully accepted the project quote.',
            });
            await this.initialize();
          }
        })
        .catch(() => {
          this.$notify.error({
            title: 'Project Quote Transaction Error.',
            message: 'The project has been updated recently.',
          });
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        })
        .finally(() => {
          this.loadingConfirmAcceptQuote = false;
          this.onCancelModals();
        });
    },

    async submitRejectProjectQuote() {
      this.loadingConfirmAcceptQuote = true;
      // const { projectRefId } = this.$route.params;
      const projectQuoteId = this.selectedProjectQuote?.id;
      const formData = {
        projectQuoteStatusId: ProjectQuoteStatusEnum.QUOTE_REJECTED,
      };

      await this.updateProjectQuoteStatus({ projectId: this.selectedProject?.id, projectQuoteId, formData })
        .then(async (response) => {
          if (response) {
            this.$notify.success({
              title: 'Project Quote Rejected.',
              message: 'You successfully rejected the project quote.',
            });
            await this.initialize();
            // this.selectedProjectQuote.projectQuoteStatus = { ...this.selectedProjectQuote.projectQuoteStatus, projectQuoteStatus: response?.projectQuoteStatus };
            // this.$router.push({ name: PROJECT_OVERVIEW_ROUTE, params: { projectRefId } });
          }
        })
        .catch(() => {
          this.$notify.error({
            title: 'Project Quote Transaction Error.',
            message: 'The project has been updated recently.',
          });
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        })
        .finally(() => {
          this.loadingConfirmAcceptQuote = false;
          this.onCancelModals();
        });
    },
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.estimates {
   display: flex;
   gap: 1.5rem;
   height: 100%;
   max-width: 1600px;
   position: relative;
   padding-bottom: 4rem;

   &__empty-state {
     position: absolute;
     top: -25px;
     bottom: 0;
     left: 0;
     right: 0;
   }
//    background:red;

   &__request-list {
       width: 398px;
       display: flex;
       flex-direction: column;
       gap: 1rem;
       position: relative;
    //    background:pink;
       box-sizing: border-box;
   }
   &__header {
        display:flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        position: sticky;
        top: 0;
        z-index: 1;
        background: #F1F6FB;
        width: 100%;
        padding-bottom: .7rem;
        height: 50px;

        .title-container {
            display:flex;
            gap: 1rem;
            width: 100%;
            padding-right: 1rem;
           .title {
                margin: 0;
                font-family: Mulish;
                font-size: 28px;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: 0.25px;
                color: #0C0F4A;
            }
            .profile-cta {
              @extend .title;
              font-size: 12px;
              font-weight: 600;
              line-height: 16px;
              letter-spacing: 0.4000000059604645px;
              color: #FFA500;
            }
            .count {
                width: 35px;
                height: 35px;
                display:flex;
                align-items: center;
                justify-content: center;
                border-radius: 12px;
                border: 1px solid #FAA100;

                p {
                    font-family: Mulish;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 25px;
                    letter-spacing: 0.25px;
                    text-align: center;
                    color: #0C0F4A;
                }
            }
        }

        .action-container {
            display:flex;
            align-items: center;
            gap: 1.5rem;
        }
   }

   &__all-request {
       height: calc(100% - 120px);
       display: flex;
       flex-direction: column;
       gap: 1rem;
   }
   &__summary {
       width: 100%;
       position: relative;
       z-index: 2;
    //    height: calc(100% - 50px);
    //    overflow-y: scroll;
   }

   &__summary-content {
      //  display: flex;
      //  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      gap: 1.5rem;
      width: 100%;
      padding-bottom: 2rem;
      height: calc(100vh - 210px);
      display: grid;
      grid-template-columns: 1fr 1.5fr;
   }

   &__summary-column-one {
      //  width: 378px;
      //  min-width: 300px;
      width: 100%;
      margin-top: .8rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .profile {
          border-radius: 8px;
          min-height: 280px;
      }
   }

   &__summary-column-two {
       width: 100%;
       margin-top: .8rem;
       min-width: 540px;
   }
   &__action-container-mobile {
      display: none;
   }
}
.agreement-action {
    border: 1px solid rgba(12, 15, 74, 0.1);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem;
    box-sizing: border-box;
    height: 50px;

    .el-checkbox__label {
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        color: rgba(255, 255, 255, 1);
    }
    :deep(.el-checkbox__input .el-checkbox__inner) {
        border-radius: 100px;
        background: rgba(12, 15, 74, 0.15);
        border: 1 solid rgba(12, 15, 74, 0.15);
        width: 18px;
        height: 18px;
    }
    :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
        display: none;
    }
    :deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
        border: 0px solid rgba(12, 15, 74, 0.15);
        border-radius: 100px;
        background: rgba(12, 15, 74, 0.15);
        transition: 0.2s ease-in-out;
    }
    :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
        border-radius: 100px;
        border: 4px solid #ffa500;
        background: #ffa500;
        transition: 0.2s ease-in-out;
    }

    p {
        margin: 0;
        font-family: Mulish;
        font-size: 10px;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0.25px;
        text-align: left;
        color: rgba(12, 15, 74, 0.5);

        span {
            font-family: Mulish;
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0.25px;
            text-align: left;
            color: rgba(250, 161, 0, 1);
        }
    }
}

.button {
    height: 40px;
    padding: 0 1.5rem;
    box-sizing: border-box;
    background-color: rgba(12, 15, 74, 0.05);
    border: none;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: rgba(12, 15, 74, 0.5);
}
.active {
    background-color: #FFA500;
    color: #fff;
}

.reject {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-align: center;
  color: rgba(42, 42, 42, 0.5);
  cursor:pointer;
}

@media screen and (max-width: 1438px) {
  .estimates {
    padding-bottom: 0;
    &__summary {
      overflow: scroll;
      // height: calc(100% - 50px);
      // height: 100%;
      height: calc(100vh - 8rem);
    }
    &__summary-content {
      display: flex;
      flex-direction: column;
    }
    &__summary-column-two {
      max-height: 620px;
      min-width: auto;
    }
    &__action-container-mobile {
      display: flex;
    }
    &__header {
      .title-container {
        width: 100%;
      }
      .action-container {
        display: none;
      }
    }
  }
}
@include media-lg-max-width() {
  .estimates {
    &__action-container-mobile {
      flex-direction: column;
    }
  }
}
@include media-md-max-width() {
  .estimates {
    &__all-request {
      height: calc(100% - 175px);
    }
    &__summary {
      z-index: 0;
      height: 100%;
   }
  }
}
@include media-sm-max-width() {
  .estimates {
    top: 4rem;
    padding-bottom: 0;
    &__header {
      height: 35px;
      padding-bottom: 0;

      .title-container {
        .title {
          font-size: 18px;
          line-height: 30px;
        }
        .count {
          width: 32px;
          height: 32px;
          border-radius: 10px;
        }
        .profile-cta {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    &__request-list {
      width: 100%;
      gap: 0;
    }
    &__all-request {
      height: calc(100% - 61px);
    }
    &__summary {
      flex-direction: column;
      height: 100%;
    }
    &__summary-content {
      height: auto;
    }
    &__summary-column-two {
      max-height: none;
      // padding-bottom: 2rem;
    }

    .close {
      display: none;
    }
    .open {
      display: flex;
    }
  }
}
@include media-xs-max-width() {
  .estimates {
    &__header {
      .title-container {
        .title {
          font-size: 16px;
        }
      }
    }
  }
  .button {
    padding: 0 2.5rem;
  }
}
</style>
