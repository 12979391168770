import { WarningFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    components: {
        WarningFilled
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
        statusNotAdded() {
            return this.activeUserWorkstation?.verificationStatus === UserVerificationStatusEnum.NOT_ADDED;
        },
        statusPending() {
            return this.activeUserWorkstation?.verificationStatus === UserVerificationStatusEnum.PENDING_VERIFICATION;
        },
        statusVerified() {
            return this.activeUserWorkstation?.verificationStatus === UserVerificationStatusEnum.VERIFIED;
        },
    }
});
