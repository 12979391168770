<template>
  <el-scrollbar>
    <div class="estimate-all-request-list" v-if="projectQuotes.length">
        <EstimatesCardItem
          v-for="(projectQuote, index) in projectQuotes"
          :key="index"
          :selectedProjectQuote="selectedProjectQuote"
          :projectQuote="projectQuote"
          @view-summary-details="viewSummaryDetails"
          />
    </div>
  </el-scrollbar>
</template>
<script>
import EstimatesCardItem from '@/modules/project-details-children/components/EstimatesCardItem.vue';

export default {
  components: {
    EstimatesCardItem
  },
  props: ['projectQuotes', 'selectedProjectQuote'],
  emits: ['select-project-quote'],
  created() {
  },
  methods: {
    viewSummaryDetails(projectQuoteId) {
      this.$emit('select-project-quote', projectQuoteId);
    },
  }
};
</script>
<style lang="scss" scoped>
  .el-scrollbar {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    // margin-top: 57px;
    // padding-bottom: 3rem;
    :deep(.el-scrollbar__view) {
      padding: 0;
    }
    .estimate-all-request-list {
        display:flex;
        flex-direction: column;
        gap:1rem;
        padding-bottom: 1rem;
    }
  }
</style>
