import { WarningFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    name: 'profile-verified-icon',
    props: ['userIdentification', 'showLabel', 'userProfileState', 'smallLayout'],
    emits: ['open-identity-verified-dialog'],
    components: {
        WarningFilled
    },
    data() {
        return {
            UserVerificationStatusEnum
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['viewedUser']),
    },
    methods: {
        openDialog() {
            this.$emit('open-identity-verified-dialog');
        }
    }
});
