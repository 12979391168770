import { defineComponent } from 'vue';
import DefaultDynamicStatusModalContent from '@/core/components/common/modals/DefaultDynamicStatusModalContent.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
export default defineComponent({
    name: 'reject-estimate-modal',
    emits: ['on-confirm', 'on-cancel'],
    props: ['loading'],
    components: {
        DefaultDynamicStatusModalContent,
        BaseModal
    },
    data() {
        return {
            type: 'rejectEstimate',
            content: {
                hasTwoButton: true,
                /* eslint-disable global-require */
                icon: `${require('@/assets/icons/reject-estimate-icon.svg')}`,
                /* eslint-disable global-require */
                statusText: 'Are you sure you want to reject the estimate?',
                hasDescription: true,
                descriptionText: 'All projects should follow our',
                spanText: 'Terms & Conditions',
                buttonActiveLabel: 'reject',
            }
        };
    },
    methods: {
        onConfirm() {
            this.$emit('on-confirm');
        },
        onCancel() {
            this.$emit('on-cancel');
        }
    }
});
