import { defineComponent, ref } from 'vue';
// import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
export default defineComponent({
    components: {
    // UserThumbnail
    },
    props: ['user'],
    setup() {
        const thumbnailCount = ref(4);
        return {
            thumbnailCount
        };
    },
    created() {
    },
    computed: {
        profileImage() {
            return this.user?.userPublicProfile?.profileImage || null;
        },
    },
});
