<template>
  <div class="phase-summary-card-po">
    <div class="list-details-container">
        <div class="header">
            <GlobalDynamicHeader title="Estimate" :hasEndCustomComponent="true" :hasStartCustomComponent="false">
                <!-- <template #start-custom-component>
                    <ActionItemComponent
                    label="Download PDF"
                    fontColor="#FFA500"
                    @call-to-action="downloadPdf"/>
                </template> -->
                <template #end-custom-component>
                    <ActionItemComponent
                    label="View schedule"
                    icon="date.svg"
                    fontColor="#FFA500"
                    @call-to-action="gotoSchedule"/>
                </template>
            </GlobalDynamicHeader>
        </div>
        <div class="list-info">
          <SingleDataWidget
            width="100%"
            :data="getFormattedDate"
            label="Start date"/>
          <SingleDataWidget
            width="100%"
            :data="projectQuote?.projectStages?.length.toString()"
            label="Total Phases"/>
          <SingleDataWidget
            width="100%"
            :data="projectQuote?.quoteTotalDuration + ' day(s)'"
            label="Total Duration"/>
        </div>
        <div class="list-tasks">
        <el-scrollbar>
            <div class="phase-content">
                <PhaseSummaryItem
                v-for="(projectStage, index) in projectQuote?.projectStages"
                :key="index"
                :data="projectStage"
                :index="index"
                @clicked-phase-index="setClickedPhaseIndex($event)"/>
            </div>
        </el-scrollbar>
        </div>
    </div>
    <div class="list-price">
      <!-- <div class="list-price__item">
        <div class="list-price__item__description">Sub total</div>
        <div class="list-price__item__price">£4,860.00</div>
      </div>
      <div class="list-price__item">
        <div class="list-price__item__description">VAT (0%)</div>
        <div class="list-price__item__price">£0.00</div>
      </div> -->
      <!-- <div class="list-price__item">
        <div class="list-price__item__description fee">
            <span> Service fee <span v-if="getTotalServiceFeePercent > 0">% ({{ getTotalServiceFeePercent }})</span></span>
            <inline-svg :src="require(`@/assets/icons/remove-black.svg`)" />
        </div>
        <div class="list-price__item__price">{{ formattedTotalServiceFee }}</div>
      </div> -->
      <!-- <el-divider/> -->
      <div class="list-price__item-total">
        <div class="list-price__item-total__description">Total</div>
        <div class="list-price__item-total__price">{{ $filters.formatToCurrency(projectQuote?.quotePrice?.total) }}</div>
      </div>
    </div>

    <!-- MODALS -->
    <EstimatePhaseAttachmentUploadModal
      v-if="clickedPhaseIndex !== null"
      :show="showPhaseUploadAttachmentModal"
      @close="onClosePhaseAttachmentModal"
      :phaseIndex="clickedPhaseIndex"
      :quotes="projectQuote"/>
  </div>
</template>
<script>

import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import GlobalDynamicHeader from '@/core/components/common/header/GlobalDynamicHeader.vue';
import EstimatePhaseAttachmentUploadModal from '@/core/components/common/modals/EstimatePhaseAttachmentUploadModal.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import SingleDataWidget from '@/core/components/ui/SingleDataWidget.vue';
// import PhaseSummaryItemComponent from '@/modules/project-details-children/components/PhaseSummaryItemComponent.vue';
import PhaseSummaryItem from '@/modules/project-estimate/components/PhaseSummaryItem.vue';
import { JOB_TEMPLATE_STORE } from '@/store/modules/job-template';

export default defineComponent({
  components: {
    // PhaseSummaryItemComponent,
    SingleDataWidget,
    PhaseSummaryItem,
    GlobalDynamicHeader,
    ActionItemComponent,
    EstimatePhaseAttachmentUploadModal
  },
  props: ['projectQuote', 'projectRefId', 'quoteId'],

  data() {
    return {
      clickedPhaseIndex: null,
    };
  },

  computed: {
    ...mapGetters(['isProjectOwner']),
    ...mapGetters(JOB_TEMPLATE_STORE, ['showPhaseUploadAttachmentModal']),

    getFormattedDate() {
      return moment(this.projectQuote?.suggestedStartDate).format('DD/MM/YYYY');
    },
  },
  methods: {
    ...mapActions(JOB_TEMPLATE_STORE, ['setShowPhaseUploadAttachmentModal']),

    gotoSchedule() {
      let selectedQuoteId = this.quoteId;

      // when a estimate proposal selection has been made
      if (this.isProjectOwner && !selectedQuoteId) {
        selectedQuoteId = this.projectQuote.id;
      }

      if (this.projectRefId && selectedQuoteId) {
        const params = {
          projectRefId: this.projectRefId,
          quoteId: selectedQuoteId
        };

        this.$router.push({ name: 'estimate-schedule', params });
      } else {
        // console.log('somethins wrong');
      }
    },

    onClosePhaseAttachmentModal() {
      this.setShowPhaseUploadAttachmentModal(false);
      this.clickedPhaseIndex = null;
    },

    setClickedPhaseIndex(index) {
      this.clickedPhaseIndex = index;
      if (this.clickedPhaseIndex !== null && this.clickedPhaseIndex !== 'undefined') {
        this.setShowPhaseUploadAttachmentModal(true);
      }
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;
.el-divider {
  margin: 0.5rem 0;
}
.phase-summary-card-po {
      background-color: #fff;
      padding: 1.5rem;
      // padding-bottom: 6rem;
      min-height: auto;
      // height: calc(100vh - 350px);
      border-radius: 10px;
      display:flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      height: calc(100vh - 286px);

      .list-details-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        // height: calc(100vh - 540px);
        height: calc(100vh - 490px);
      }

      .list-info {
        display: flex;
        gap: 20px;

        .card {
          flex: 100%;
          text-align: center;
          padding: 12px;
          background-color: rgba(12, 15, 74, 0.05);
          line-height: 25px;
          letter-spacing: 0.25px;
          border-radius: 8px;

          &__title {
            font-weight: 700;
            font-size: 14px;
          }

          &__description {
            color: rgba(12, 15, 74, 0.7);
            font-size: 12px;
            border-radius: 8px;
          }
        }
      }

      .list-tasks {
            // height: calc(100% - 10rem);
            height: 100%;

            .el-scrollbar {
                width: 100%;
                box-sizing: border-box;
                padding: 0;

                :deep(.el-scrollbar__view) {
                    padding: 0;
                }

                .phase-content {
                    display:flex;
                    flex-direction: column;
                    gap:1rem;
                    // padding-bottom: 1rem;
                    padding: .3rem .3rem 1rem .3rem;
                }
            }
        }

      .list-price {
        background: linear-gradient(180deg,
            rgba(255, 255, 255, 0) 0%,
            #ffffff 23.85%);

        &__item {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          line-height: 30px;
          margin-bottom: 5px;

          &__description {
            color: rgba(12, 15, 74, 0.5);
            font-weight: 400;
          }

          &__price {
            color: #0c0f4a;
            font-weight: 600;
          }

          &-total {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            line-height: 30px;

            &__description {
              font-weight: 600;
            }

            &__price {
              font-weight: 600;
            }
          }
        }
      }
    }
@include media-sm-max-width() {
    .el-card {
        height: 100%;
        .card-phase-summary-content {
            .phase-container {
                height: 100%;
            }
        }
    }
}
@include media-xs-max-width() {
    .el-card {
        height: 100%;
        .card-phase-summary-content {
            .phase-summary-header {
                .header-row-one {
                  justify-content: space-between;
                  width: 100%;

                  .header-cta-container {
                      width: auto;
                  }
                }
                .header-row-two {
                    gap: .5rem;
                }
            }
        }
    }
}
</style>
