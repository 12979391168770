// import { Edit } from '@element-plus/icons';
// import { User } from '@/core/packages/shared-library';
import moment from 'moment';
import { defineComponent } from 'vue';
// import { mapActions, mapGetters } from 'vuex';
import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import StatusIconComponent from '@/modules/dashboard/components/new-components/StatusIconComponent.vue';
import ProfileVerifiedIcon from '@/modules/profile/components/ProfileVerifiedIcon.vue';
// import { USERS_STORE } from '@/store/modules/users';
export default defineComponent({
    components: {
        // Edit,
        UserThumbnail,
        ProfileVerifiedIcon,
        StatusIconComponent
    },
    props: ['user'],
    computed: {
        registeredDate() {
            return moment(this.user.dateCreated).format('MMM YYYY');
        },
        profileImage() {
            return this.user?.userPublicProfile?.profileImage || null;
        },
        userProfileState() {
            return this.user?.userVerificationStatus;
        },
        statusNotAdded() {
            return this.user?.userVerificationStatus === UserVerificationStatusEnum.NOT_ADDED;
        },
        statusPending() {
            return this.user?.userVerificationStatus === UserVerificationStatusEnum.PENDING_VERIFICATION;
        },
        statusVerified() {
            return this.user?.userVerificationStatus === UserVerificationStatusEnum.VERIFIED;
        },
        statusRejected() {
            return this.user?.userVerificationStatus === UserVerificationStatusEnum.REJECTED;
        },
    },
    methods: {
        routeToProfile(userId) {
            this.$router.push({ name: 'profile/about', params: { userId } });
        },
    }
});
