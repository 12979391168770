<template>
  <div class="empty-state flex-column jc-c ai-c">
    <div class="empty-state__content flex-column jc-c ai-c">
      <inline-svg class="empty-state__cta-icon" width="165" height="200" :src="require(`@/assets/icons/empty-project-icon.svg`)"></inline-svg>
      <p class="empty-state__message default-text">{{description}}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: ['description']
};
</script>
<style lang="scss" scoped>
.empty-state {
    width: 100%;
    height: 100%;

    &__content {
      width: 100%;
      height: 100%;
    }
    &__message {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.4000000059604645px;
        color: rgba(12, 15, 74, 0.5);
        text-align:center;
    }
}
</style>
