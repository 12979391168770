<template>
    <el-card @click="viewSummaryDetails(projectQuote.id)"
        v-bind:class="{ 'active' : selectedProjectQuote && selectedProjectQuote?.id == projectQuote.id }"
    >
        <!-- <span><i>{{ isQuoteAccepted ? 'Accepted' : '' }}</i></span> -->
        <!-- <span>{{ isQuoteConfirmed ? 'Confirmed' : '' }}</span> -->
        <!-- <el-tooltip
          class="box-item"
          effect="dark"
          content="Accepted estimate"
          placement="left-start"
          v-if="isQuoteAccepted"
        >
          <el-icon class="estimates-card-item-icon cursor-pointer" :size="25" color="#5DBA59"><SuccessFilled/></el-icon>
        </el-tooltip> -->
        <div v-if="isQuoteAccepted" class="estimates-card-item-status flex-row ai-c">
          <p class="estimates-card-item-status__text default-text">Accepted</p>
          <el-icon :size="17" color="#5DBA59"><SuccessFilled/></el-icon>
        </div>
        <div class="estimates-item-content flex-row ai-c jc-sb">
            <div class="estimates-item-content__content-details flex-row ai-c">
               <UserThumbnail
                borderRadius="8px"
                width="50px"
                height="50px"
                :key="profileImage"
                :source="profileImage"
                >
                </UserThumbnail>
                <div class="estimates-item-content__item-details flex-column">
                    <p class="estimates-item-content__name default-text">{{ name }}</p>
                    <div class="flex-row ai-c gap-half">
                      <p class="estimates-item-content__total default-text">
                        {{ projectQuotePrice ? $filters.formatToCurrency(projectQuotePrice) : 0 }}
                      </p>
                      <span class="estimates-item-content__state default-text active">
                        ( {{ projectQuote.type === estimateState.VARIABLE ?
                          estimateState.VARIABLE : estimateState.FIXED }} )
                      </span>
                    </div>
                </div>
            </div>
            <el-icon :size="15" :color="selectedProjectQuote && selectedProjectQuote?.id == projectQuote.id ? '#FFF' : 'rgba(12, 15, 74, 0.5)'" ><ArrowRightBold/></el-icon>
        </div>
    </el-card>
</template>
<script>
import { ArrowRightBold, SuccessFilled } from '@element-plus/icons';

import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import estimateState from '@/core/constants/estimate';
import { ProjectQuoteStatusEnum } from '@/core/packages/shared-library';

export default {
  components: {
    UserThumbnail,
    ArrowRightBold,
    SuccessFilled
  },

  emits: ['view-summary-details'],

  props: ['projectQuote', 'selectedProjectQuote'],

  data() {
    return {
      estimateState,
    };
  },

  computed: {

    projectQuoteCreator() {
      return this.projectQuote.projectQuoteCreator;
    },

    projectQuotePrice() {
      return this.projectQuote.totalWithServiceFee;
    },

    isQuoteAccepted() {
      return this.projectQuote.projectQuoteStatus.id === ProjectQuoteStatusEnum.QUOTE_ACCEPTED;
    },

    isQuoteConfirmed() {
      return this.projectQuote.projectQuoteStatus.id === ProjectQuoteStatusEnum.QUOTE_CONFIRMED;
    },

    name() {
      return `${this.projectQuoteCreator?.firstName} ${this.projectQuoteCreator?.lastName}`;
    },
  },
  methods: {
    viewSummaryDetails(projectQuoteId) {
      this.$emit('view-summary-details', projectQuoteId);
    },
  }
};
</script>
<style lang="scss" scoped>
.el-card.is-always-shadow {
   box-shadow: none;
   border: none;
}
.el-card {
    height: 112px;
    border-radius: 16px;
    cursor: pointer;
    position: relative;
    .estimates-card-item-icon {
      position: absolute;
      top: .7rem;
      right: .7rem;
    }
    .estimates-card-item-status {
      position: absolute;
      top: .5rem;
      right: .5rem;
      gap: .5rem;
      box-shadow: 0px 4px 20px rgba(12, 15, 74, 0.1);
      border-radius: 8px;
      box-sizing: border-box;
      padding: .1rem .7rem;
      background: #FFF;

      &__text {
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
        color: #FFA500;
      }
    }
    .estimates-item-content {
      height: 100%;

      &__content-details {
        gap: .7rem;
      }
      &__item-details {
        gap: 0;
      }
      &__name {
          font-size: 17px;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: 0.25px;
          text-align: left;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 150px;
          line-height: 1.2em;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
      }
      &__total {
          font-size: 15px;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: 0.25px;
          text-align: left;
          color: rgba(12, 15, 74, 0.5);
          margin: 0;
      }

      &__state {
        color: #FFF;
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.25px;
        white-space: nowrap;
      }

      &__state.active {
        color: #FFA500;
      }
    }
}
.el-card.active {
  background: rgba(42, 82, 203, 1);

  .estimates-item-content {
    &__name {
      color: #FFF;
    }
    &__total {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
:deep(.el-card__body) {
    height: 100%;
    box-sizing: border-box;
}
</style>
